<template>
	<div>
		<div class="py-3">
			<div class="w-50 position-absolute mh100 bg-credito" style="bottom: 0px; top:0px; left: 0px; min-height: 100%;"></div>
			<div class="w-50 position-absolute mh100 bg-inversion" style="bottom: 0px; top:0px; right: 0px; min-height: 100%;"></div>
			<div class="position-relative m-auto h-100">
				<div class="row justify-content-center align-items-center h-100 mx-0" style="min-height: calc(100vh - 164px);">
					<div class="col-md-10">
						<div class="card overflow-hidden">
							<div class="card-header text-center bg-primary text-white">
								<h2 class="font-weight-bold mb-0">Cupón</h2>
							</div>
							<div class="card-body bg-light">
								<div class="row justify-content-center">
									<div class="col-md-8">
										<div class="card">
											<div class="card-body">
												<div class="d-flex align-items-center">
													<img v-lazy="$filters.storage(cupon.fotoSocioNegocio, 20)" width="20" height="20">
													<span class="ml-3">{{cupon.nombreSocioNegocio}}</span>
												</div>
												<hr class="bg-primary">
												<div class="row justify-content-between">
													<div class="col-md-3 mb-3">
														<div class="mb-3">
															<p class="font-weight-bold mb-0">Cupón</p>
															<small style="font-size: 75%;">#{{cupon.codigo}}</small>
														</div>
													</div>
													<div class="col-md-6 mb-3">
														<div class="d-flex flex-column h-100">
															<div class="mb-3">
																{{cupon.descripcionProducto}}
															</div>
														</div>
													</div>
													<div class="col-md-3 mb-3 text-center text-primary">
														<strong>{{cupon.nombreBeneficio}}</strong>
														<br>
														<img v-lazy="$filters.storage(cupon.fotoBeneficio, 150)" class="img-fluid" alt="Beneficio">
													</div>
												</div>
												<div class="row mt-auto text-center">
													<div class="col-md-8">
														<p class="font-weight-bold text-primary mb-0">Beneficiario:</p>
														{{cupon.nombre}} {{cupon.apellido}}
													</div>
													<div class="col-md-4">
														<p class="font-weight-bold text-primary mb-0">Emisión:</p>
														{{cupon.fechaCreacion}}
													</div>
												</div>
											</div>
										</div>
										<div class="text-center">
											<small><strong>Descripción beneficio:</strong> {{cupon.descripcionBeneficio}}</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default{
		data(){
			return{
				cupon: {},
				loadingData: false
			}
		},
		mounted(){
			this.loadData()
		},
		methods:{
			loadData(){
				this.loadingData = true
				this.$store.dispatch('getCupon', {
					id: this.id
				}).then(response => {
					this.cupon = response.data || {}
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingData = false
				})
			}
		},
		computed:{
			id(){
				return this.$route.params.id || -1
			}
		}
	}
</script>
	
<template>
<div class="py-3">
	<div class="w-50 position-absolute mh100 bg-credito" style="bottom: 0px; top:0px; left: 0px; min-height: 100%;"></div>
	<div class="w-50 position-absolute mh100 bg-inversion" style="bottom: 0px; top:0px; right: 0px; min-height: 100%;"></div>
	<div class="position-relative m-auto h-100">
		<div class="row justify-content-center align-items-center h-100 mx-0" style="min-height: calc(100vh - 164px);">
			<div class="col-md-10">
				<div class="card overflow-hidden">
					<div class="card-header text-center bg-primary text-white">
						<h2 class="font-weight-bold mb-0">Club de Beneficios</h2>
					</div>
					<div class="card-body text-center bg-light">
						<div class="row justify-content-center">
							<div class="col-md-10">
								<div class="row align-items-stretch">
									<div class="col-lg-3 mb-3">
										<div class="card h-100">
											<div class="card-body text-center">
												<div class="mb-3">
													<a v-if="validURL(beneficio.sitio)" href="{{beneficio.sitio}}" target="_blank">
														<img v-lazy="beneficio.fotoSocio" v-bind:alt="beneficio.nombreSocio" class="img-fluid">
													</a>
													<img v-if="!validURL(beneficio.sitio)" v-lazy="beneficio.fotoSocio" v-bind:alt="beneficio.nombreSocio" class="img-fluid">
												</div>
												<p class="text-primary font-weight-bold">{{beneficio.nombreSocio}}</p>
												<div class="card-content center">
													<ul class="my-3 p-0">
														<li style="display: inline-block; margin-right: 5px;" v-if="validURL(beneficio.facebook)" title="Facebook">
															<a href="{{beneficio.facebook}}" target="_blank">
																<img src="@/assets/image/facebook.webp" width="30px" height="30px" alt="Facebook">
															</a>
														</li>
														<li style="display: inline-block; margin-right: 5px;" v-if="validURL(beneficio.instagram)" title="Instagram">
															<a href="{{beneficio.instagram}}" target="_blank">
																<img src="@/assets/image/instagram.webp" width="30px" height="30px" alt="Instagram">
															</a>
														</li>
														<li style="display: inline-block; margin-right: 5px;" v-if="validURL(beneficio.twitter)" title="Twitter">
															<a href="{{beneficio.twitter}}" target="_blank">
																<img src="@/assets/image/twitter.webp" width="30px" height="30px" alt="twitter">
															</a>
														</li>
													</ul>
												</div>
												{{beneficio.descripcionNegocio}}
											</div>
										</div>
									</div>
									<div class="col-lg-9 mb-3">
										<div class="card h-100">
											<div class="card-body">
												<div class="row justify-content-between h-100">
													<div class="col-md-4 mb-2">
														<div class="text-primary font-weight-bold mb-2">
															{{beneficio.nombre}}
														</div>
														<div class="p-3">
															<img v-lazy="beneficio.foto" v-bind:alt="beneficio.nombre" class="img-fluid">
														</div>
													</div>
													<div class="col-md-7 h-100 d-flex flex-column">
														<div class="text-primary font-weight-bold mb-2 text-left">
															Descripción del producto
														</div>
														<div class="mb-3 text-left">
															{{beneficio.descripcionProducto}}
														</div>
														<div class="text-primary font-weight-bold mb-2 text-left">
															Descripción del beneficio
														</div>
														<div class="mb-3 text-left">
															{{beneficio.descripcionBeneficio}}
														</div>
														<div class="text-center mt-md-auto">
															<button type="button" class="btn btn-inversion rounded-pill font-weight-bold px-5 text-white mb-3" v-on:click="addCupon()" v-bind:disabled="loading" v-if="isLoggedIn">
															<BtnLoading :text="'Emitiendo cupón'" v-if="loading" />
															<span v-else>
																Obtener cupón
															</span>
															</button>
														</div>
														<div class="alert alert-primary alert-dismissible fade show" role="alert" v-if="!isLoggedIn">
															<strong><router-link :to="{name:'login'}">INICIAR SESIÓN</router-link></strong> para canjear cupón
															<button type="button" class="close" data-dismiss="alert" aria-label="Close">
															<span aria-hidden="true">&times;</span>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default{
		components: {
			BtnLoading
		},
		data(){
			return{
				loading: false,
				loadingData: false
			}
		},
		mounted(){
			this.loadData()
		},
		methods:{
			loadData(){
				this.loadingData = true
				this.$store.dispatch('getBeneficios', {
					id: this.id
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingData = false
				})
			},
			validURL(str){
				var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
			      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
			      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
			      '(\\#[-a-z\\d_]*)?$','i') // fragment locator
			    return !!pattern.test(str)
			},
			addCupon(){
				this.loading = true
				this.$store.dispatch('addCupones', {
					beneficioID: this.beneficio.id,
					usuarioID: this.currentUser.idusuario
				}).then(response => {
					window.toastr.info('El cupón fue envíado a tu correo o puedes verlo desde tu perfil', 'Aviso')
					this.$router.push({name: 'beneficios.index'})
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loading = false
				})
			}
		},
		computed:{
			id(){
				return this.$route.params.id || -1
			},
			beneficios(){
				return this.$store.getters.beneficios || []
			},
			beneficio(){
				return this.beneficios[0] || {}
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn 
			},
			currentUser(){
				return this.$store.getters.currentUser
			}
		}
	}
</script>
	
<template>
	<div class="py-3 h-100 position-relative">
		<div class="w-50 position-absolute mh100 bg-credito" style="bottom: 0px; top:0px; left: 0px; min-height: 100%;"></div>
		<div class="w-50 position-absolute mh100 bg-inversion" style="bottom: 0px; top:0px; right: 0px; min-height: 100%;"></div>
		<div class="position-relative m-auto h-100">
			<div class="row justify-content-center align-items-center h-100 mx-0" style="min-height: calc(100vh - 164px);">
				<div class="col-md-10">
					<div class="card overflow-hidden">
						<div class="card-header text-center bg-primary text-white">
							<h2 class="font-weight-bold mb-0">Club de Beneficios</h2>
						</div>
						<div class="card-body text-center bg-light">
							<div class="row justify-content-center">
								<div class="col-lg-9">
									<div class="mb-3">
										<span>En Clubcashin.com nuestra meta es tener usuarios felices. Hemos creado un club de beneficios con excelentes promociones y descuentos exclusivos para todos nuestros usuarios.
										</span>
										<br>
										<h6 class="font-weight-bold">
										Solo tienes que crear tu usuario para empezar a utilizarlos.
										</h6>
										<div class="alert alert-primary alert-dismissible fade show" role="alert" v-if="!isLoggedIn">
											<strong><router-link :to="{name:'login'}">INICIAR SESIÓN</router-link></strong> para canjear cupón
											<button type="button" class="close" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true">&times;</span>
											</button>
										</div>
									</div>
									<router-link :to="{name:'login'}" v-if="!isLoggedIn" class="btn btn-inversion rounded-pill font-weight-bold px-3 text-white mb-3">
										Registrate
									</router-link>
									<select class="custom-select mb-3" v-model="tipo">
										<option value="">Todos</option>
										<option value="-1">No definidos</option>
										<option v-for="(tipo, index) in tiposBeneficios" :key="index" v-bind:value="tipo.id">{{tipo.descripcion}}</option>
									</select>
								</div>
								<div class="col-lg-12">
									<div id="carouselExampleControls" class="carousel slide d-none d-lg-block beneficios-carrousel" data-ride="carousel">
										<div class="carousel-inner">
											<div class="form-row justify-content-center">
												<div class="col-lg-9">
													<div :class="{'carousel-item':true, 'active':number == 0}" v-for="(number, index) in numbers" :key="index">
														<div class="row">
															<div class="col-md-3 mx-md-0 mx-3 mb-3" v-for="(beneficio, index) in beneficiosFiltered.slice((index * items), ((index * items)+items))" :key="index">
																<router-link class="card cursor-pointer h-100 text-decoration-none shadow-hover" :to="{name:'beneficios.show', params:{id:beneficio.id}}">
																	<div class="card-body">
																		<p class="font-weight-bold text-truncate mb-2">
																			{{beneficio.nombre}}
																		</p>
																		<div>
																			<img v-lazy="$filters.storage(beneficio.foto || '@/image/favicon.webp', 100)" width="80" height="80" class="zoom-img">
																		</div>
																		<br>
																		<span>{{beneficio.descripcionBeneficio}}</span>
																	</div>
																</router-link>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
											<span class="carousel-control-prev-icon" aria-hidden="true"></span>
											<span class="sr-only">Previous</span>
										</a>
										<a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
											<span class="carousel-control-next-icon" aria-hidden="true"></span>
										    <span class="sr-only">Next</span>
										</a>
									</div>

									<div id="carouselExampleControls2" class="carousel slide beneficios-carrousel preguntas-carrousel d-block d-lg-none px-5 px-lg-3" data-ride="carousel">
										<div class="carousel-inner">
											<div :class="{'carousel-item':true, 'active':index == 0}" v-for="(beneficio, index) in beneficiosFiltered" :key="index">
												<div class="row">
													<div class="col-md-6 mx-md-0 mx-3 mb-3">
														<router-link class="card cursor-pointer h-100 text-decoration-none shadow-hover" :to="{name:'beneficios.show', params:{id:beneficio.id}}">
															<div class="card cursor-pointer h-100">
																<div class="card-body">
																	<p class="font-weight-bold text-truncate mb-2">
																		{{beneficio.nombre}}
																	</p>
																	<div>
																		<img v-lazy="$filters.storage(beneficio.foto || '@/image/favicon.webp', 100)" width="80" height="80" class="zoom-img">
																	</div>
																	<br>
																	<span>{{beneficio.descripcionBeneficio}}</span>
																</div>
															</div>
														</router-link>
													</div>
												</div>
											</div>
										</div>
										<a class="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
											<span class="carousel-control-prev-icon" aria-hidden="true"></span>
											<span class="sr-only">Previous</span>
										</a>
										<a class="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
											<span class="carousel-control-next-icon" aria-hidden="true"></span>
										    <span class="sr-only">Next</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				items: 8,
				loadingData: false,
				loadingTipos: false,
				tipo: ''
			}
		},
		mounted(){
			this.loadData()
			this.getTiposBeneficios()
		},
		methods:{
			loadData(){
				this.loadingData = true
				this.$store.dispatch('getBeneficios').then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingData = false
				})
			},
			getTiposBeneficios(){
				this.loadingTipos = true
				this.$store.dispatch('getTiposBeneficios').then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingTipos = false
				})
			},
		},
		computed:{
			beneficios(){
				return this.$store.getters.beneficios || []
			},
			beneficiosFiltered(){
				if (this.tipo != '') {
					if (this.beneficios.constructor === Array) {
						return this.beneficios.filter(x => x.tipoNegocioID == this.tipo)
					}
				}
				return this.beneficios
			},
			tiposBeneficios(){
				return this.$store.getters.tiposBeneficios || []
			},
			numbers(){
				var total = this.beneficiosFiltered.length/this.items
	            if (total > parseInt(total)) {
	                total = parseInt(total)+1
	            }else{
	                total = parseInt(total)
	            }
	            return Array(total).fill(0).map((x,i)=>i)
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn 
			}
		},
	}	
</script>